import styled from '@emotion/styled';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { IMenu } from 'src/interface/layout';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

import Link from 'next/link';
import { SHOW_COMMEMORATE } from 'src/constants';
import { controller } from 'src/switch-themes';
import { splitRote } from 'src/utils/helper';
import Image, { IMAGE_SIZE } from '../cards/image';

const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'), { ssr: true });
const WidgetFollow = dynamic(import('src/components/widget/widget-follow'));
const WidgetSearchFullPage = dynamic(import('src/components/widget/widget-search-full-page'));
const NavDesktop = dynamic(import('src/components/layout/nav-desktop'));
const NavMobile = dynamic(import('src/components/layout/nav-mobile'));

type PCategory = any & {
  data: IMenu[];
};
interface Props {
  gray?: boolean;
}

const { title, name, image } = controller;
const Header: NextPage<PCategory> = ({ data, sessionSevId }) => {
  const _data: IMenu[] = data;
  const router = useRouter();
  return (
    <>
      <HeaderSocial>
        <div className={`container`}>
          <WidgetFollow
            showTitle={false}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'header',
              position: 'on:header'
            }}
          />
        </div>
      </HeaderSocial>
      <HeaderLogo className='container' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        {router.asPath === `${splitRote(router) || '/'}` ? (
          <h1 className='text-header-logo'>
            <Link href={`${splitRote(router)}/`} passHref>
              <a // ! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    section: 'header',
                    position: 'on:header',
                    data: {
                      title: 'logo',
                      heading: 'logo'
                    }
                  })
                }
              >
                <span>{title}</span>
                <Image image={image.headerLogo} title={name.en} external={true} size={IMAGE_SIZE.MD_WEBP} />
              </a>
            </Link>
            {/* <a
              href={`${splitRote(router)}/`}
              role='link'
              title={title}
              aria-label={title}
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'header',
                  position: 'on:header',
                  data: {
                    title: 'logo',
                    heading: 'logo'
                  }
                })
              }
            >
              <span>{title}</span>
              <Image image={headerLogo} title={name.en} external={true} size={IMAGE_SIZE.XS_WEBP} />
            </a> */}
          </h1>
        ) : (
          <Link href={`${splitRote(router)}/`} passHref>
            <a
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'header',
                  position: 'on:header',
                  data: {
                    title: 'logo',
                    heading: 'logo'
                  }
                })
              }
            >
              <Image image={image.headerLogo} title={name.en} external={true} size={IMAGE_SIZE.MD_WEBP} />
            </a>
          </Link>
          // <a
          //   href={`${splitRote(router)}/`}
          //   title={title}
          //   role='link'
          //   aria-label={title}

          // >
          // </a>
          // <a
          //   href={`${splitRote(router)}/`}
          //   title={title}
          //   role='link'
          //   aria-label={title}
          //   // ! DATALAYER
          //   onClick={() =>
          //     sendDataLayerGlobal({
          //       type: DATALAYER_TYPE.TRACK_POSITION,
          //       router: router?.pathname,
          //       section: 'header',
          //       position: 'on:header',
          //       data: {
          //         title: 'logo',
          //         heading: 'logo'
          //       }
          //     })
          //   }
          // >
          //   <Image image={headerLogo} title={name.en} external={true} size={IMAGE_SIZE.XS_WEBP} />
          // </a>
        )}
      </HeaderLogo>

      <HeaderMenu className='desktop' gray={SHOW_COMMEMORATE == 'true'}>
        <div className={`container header`}>
          <NavDesktop
            sessionSevId={sessionSevId}
            _data={_data}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              position: 'on:header',
              section: 'header'
            }}
          />
        </div>
      </HeaderMenu>
      <HeaderMenu className='mobile' gray={SHOW_COMMEMORATE == 'true'}>
        <>
          <div className={`container header`}>
            <NavMobile
              _data={_data}
              // ! DATALAYER
              eventDataLayer={{
                type: DATALAYER_TYPE.TRACK_POSITION,
                router: router?.pathname,
                position: 'on:header',
                section: 'header'
              }}
            />
          </div>
          <WidgetSearchFullPage />
          <WidgetLogin sessionSevId={sessionSevId} />
        </>
      </HeaderMenu>
    </>
  );
};

export default Header;

const HeaderLogo = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 186px;
  max-width: 100%;
  padding: 0 15px;
  .text-header-logo {
    margin: 0;
    overflow: hidden;
    position: relative;

    span {
      text-indent: 1000em;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      position: absolute;
      width: 100%;
    }
  }
  .lazyload-wrapper {
    width: 280px;
  }

  @media (max-width: 475px) {
    min-height: 110px;
    padding: 0 15px 0 10px;
    .lazyload-wrapper {
      width: 180px;
    }
  }
  .lazyload-wrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
`;

const HeaderMenu = styled.div<Props>`
  top: 0;
  position: sticky;
  min-height: 54px;
  background-color: var(--white-color);
  z-index: 25;
  box-shadow: 0px 5px 6px -6px rgba(0, 0, 0, 0.3);

  &.desktop {
    display: block;
    border-top: 30px solid var(--tertiary-color);

    .header {
      padding: 0 15px 0 0px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  &.mobile {
    background-color: var(--tertiary-color);
    align-items: center;
    min-height: 40px;
    display: none;
    @media (max-width: 768px) {
      display: flex;
      .header {
        padding: 0 0 0 10px;
      }
    }
  }
  .header {
    svg {
      font-size: 26px;
      color: var(--text-color);
    }
  }
  .header-ul {
    display: flex;
    gap: 20px;
    .header-li {
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--white-color);
        p {
          a {
            color: var(--black-color);
          }
        }
      }
      p {
        padding: 15px 5px;
        width: 100%;
        height: 100%;
        a {
          transition: all 0.3s ease-in-out;
          width: 100%;
          height: 100%;
          color: var(--text-color);
        }
      }
    }
  }
`;
const HeaderSocial = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 54px;
  background-color: var(--background-color);
  z-index: 25;
  @media (max-width: 475px) {
    min-height: 40px;
  }
  &.desktop {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }
  &.mobile {
    align-items: center;
    min-height: 48px;
    display: none;
    @media (max-width: 768px) {
      display: flex;
      .header {
        padding: 0 0 0 10px;
      }
    }
  }
  .header {
    svg {
      font-size: 26px;
      color: var(--tertiary-color);
    }
  }
  .header-ul {
    display: flex;
    gap: 20px;
    .header-li {
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--white-color);
        p {
          a {
            color: #000;
          }
        }
      }
      p {
        padding: 15px 5px;
        width: 100%;
        height: 100%;
        a {
          transition: all 0.3s ease-in-out;
          width: 100%;
          height: 100%;
          color: var(--text-color);
        }
      }
    }
  }
`;
