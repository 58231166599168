import { NextPage } from 'next';
import LazyLoad from 'react-lazyload';
import { APP_IMG } from 'src/constants';

type TImageSize = {
  XS: 'xs';
  XS_WEBP: 'xs-webp';
  MD: 'md';
  MD_WEBP: 'md-webp';
  LG: 'lg';
  LG_WEBP: 'lg-webp';
  XL: 'xl';
  XL_WEBP: 'xl-webp'
  XXL: 'xxl';
  XXL_WEBP: 'xxl-webp'
};
export const IMAGE_SIZE: TImageSize = {
  XS: 'xs',
  XS_WEBP: 'xs-webp',
  MD: 'md',
  MD_WEBP: 'md-webp',
  LG: 'lg',
  LG_WEBP: 'lg-webp',
  XL: 'xl',
  XL_WEBP: 'xl-webp',
  XXL: 'xxl',
  XXL_WEBP: 'xxl-webp'
};

type PImage = {
  image?: string;
  title?: string;
  size?: TImageSize[keyof TImageSize];
  external?: boolean;
  externalLink?: string | null;
  className?: string;
};
const Image: NextPage<PImage> = ({ image = '', title, external = false, size, externalLink = '', className = '' }) => {
  const imgUrl: string = external ? (externalLink ? `${externalLink}${image}` : `${image}`) : `${APP_IMG}${image}`;
  const source: string = `${imgUrl}?x-image-process=style/${size || 'lg-webp'}`;
  const images = (
    <picture className='resolution-image'>
      {/* <source srcSet={`${source}-webp`} media={'(max-width: 1440px)'} type='image/webp' /> */}
      {/* <source srcSet={source} media={'(max-width: 1440px)'} type='image/jpeg' />
      <source srcSet={`${imgUrl}?x-image-process=style/md`} media={'(max-width: 690px)'} type='image/jpeg' /> */}
      {/* <source srcSet={`${source}-webp`} media={'(min-width: 1440px)'} type='image/webp' /> */}
      <source srcSet={`${imgUrl}?x-image-process=style/md-webp`} media={'(max-width: 690px)'} type='image/webp' />
      <source srcSet={source} media={'(min-width: 691px)'} type='image/webp' />
      {/* <source srcSet={`${imgUrl}?x-image-process=style/md-webp`} media={'(max-width: 690px)'} type='image/webp' />
      <source srcSet={`${imgUrl}?x-image-process=style/xxl-webp`} media={'(min-width: 691px)'} type='image/webp' /> */}
      {/* <source srcSet={source} media={'(min-width: 1440px)'} type='image/jpeg' />
      <source srcSet={`${imgUrl}?x-image-process=style/md`} media={'(min-width: 690px)'} type='image/jpeg' /> */}
      <img className={className} fetchpriority='high' src={source} alt={title} title={title || ' image'} width='100%' height='100%' loading='lazy' />
    </picture>
  );

  return <LazyLoad offset={300}>{images}</LazyLoad>;
};

export default Image;
